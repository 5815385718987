<template>
  <div class="container">
    <div class="commerces__wrapper screen__wrapper">
      <Card
        v-for="commerce in commerces"
        :key="commerce.id"
        :obj="commerce"
        @click.native="info(commerce)"
      />
    </div>
  <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="loadObj"></infinite-loading>
  </div>
</template>

<script>
import Card from '@/views/Rent/Card'
import InfiniteLoading from 'vue-infinite-loading'
import InfoRent from '@/views/Rent/Info'

export default {
  name: "FavoritesRentCommerces",
  components: {
    Card,
    InfiniteLoading
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    commerces() {
      return this.$store.getters['favorites/rent/commerce'];
    },
    hasMoreResults () { 
      return this.commerces.length < this.totalCount && this.page > 1
    },
    totalCount() {
      return this.$store.getters['favorites/rent/commercePagination'].total
    },
    page() {
      return this.$store.getters['favorites/rent/commerceCurrentPage']
    }
  },
  methods: {
    async loadObj() {
      this.isLoading = true
      const page = this.page
      try {
        await this.$store.dispatch('favorites/rent/fetch', 'commerce')
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    },
    info(obj) {
      this.$modal.show(
        InfoRent,
        {info: obj}, 
        {name: 'InfoRent', transition: 'none', classes: 'modal', width: '900px', height: '92%'},
        {'before-open': this.$modal.open,'before-close': this.$modal.close}
      );
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "./style.scss";
</style>
